import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import img from "./logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  checkRolesAccess,
  getCustomWriteAccess,
  getProjectAccess,
  isEmpty,
  isVodexLoggedin,
} from "../CommonComponents/Common";
import { changeMenu } from "../Store/Account/Account.action";
import { Scrollbars } from "react-custom-scrollbars";
import icons from "../../../assets/icons/sideIcons";

const LeftMenuProject = () => {
  const reducer = useSelector((reducer: any) => reducer);

  const {
    AccountReducer: {
      userDetails: { role, email },
      whitelabelDetails,
      accountRoles,
      constantData,
      selectedAccount
    },
    ProjectReducer: { selectedProject },
  } = reducer;
  const history = useHistory();
  const dispatch = useDispatch();
  let [leftMenuVisibility, setLeftMenuVisibility] = useState(false);
  const [access, setAccess] = useState("");

  function changeLeftMenuVisibility() {
    setLeftMenuVisibility(!leftMenuVisibility);
  }

  function getCollapseClass() {
    return leftMenuVisibility ? "" : "collapsed";
  }

  const pathName = history.location.pathname;

  const getActiveClass = (name) => {
    return pathName.includes(name) ? "active " : "";
  };
  const { pathname } = useLocation();
  const projectId = pathname.split("/")[3];
  const recordingDisplay = ["rasa-recording", "llm-recording"];
  useEffect(() => {
    let acc: any = checkRolesAccess(accountRoles);
    setAccess(acc);
  }, [accountRoles]);

  const ProjectRenderedMenu = () => (
    <>
      <div className="sidebar-heading">
        {isVodexLoggedin() ? "Vodex" : "Phone Bot"}
      </div>
      <li className={`nav-item  ${getActiveClass("agents")} `}>
        <Link className="nav-link" to={`/account/project/${projectId}/agents`}>
          <i className={`fas fa-fw fa-robot mr-2 `}></i>

          <span className="text-capitalize ">Agents</span>
        </Link>
      </li>
      {recordingDisplay.includes(selectedProject?.botPlatform) && (
        <li className={`nav-item  ${getActiveClass("recordings")} `}>
          <Link
            className="nav-link"
            to={`/account/project/${projectId}/recordings`}
          >
            <i className={`fas fa-fw fa-headphones mr-2`}></i>

            <span className="text-capitalize">Recordings</span>
          </Link>
        </li>
      )}
      <li className={`nav-item  ${getActiveClass("audiences")} `}>
        <Link
          className="nav-link"
          to={`/account/project/${projectId}/audiences`}
        >
          <i className={`fas fa-fw fa-users mr-2`}></i>

          <span className="text-capitalize">Audiences</span>
        </Link>
      </li>
      <li className={`nav-item  ${getActiveClass("campaigns")} `}>
        <Link
          className="nav-link"
          to={`/account/project/${projectId}/campaigns`}
        >
          <i className={`fas fa-fw fa-user mr-2`}></i>

          <span className="text-capitalize">Campaigns</span>
        </Link>
      </li>

      <hr className="sidebar-divider" />

      <div className="sidebar-heading  ">Project Settings</div>
      <li className={`nav-item  ${getActiveClass("call-settings")} `}>
        <Link
          className="nav-link"
          to={`/account/project/${projectId}/call-settings`}
        >
          <i className={`fas fa-fw fa-phone-square-alt mr-2`}></i>

          <span className="text-capitalize">Call Settings</span>
        </Link>
      </li>
      <li className={`nav-item  ${getActiveClass("common")} `}>
        <Link className="nav-link" to={`/account/project/${projectId}/common`}>
          <i className={`fas fa-fw fa-cogs mr-2`}></i>

          <span className="text-capitalize">Messaging</span>
        </Link>
      </li>
      {selectedAccount?.params?.dialerConfig && <li className={`nav-item  ${getActiveClass("dialer")} `}>
        <Link className="nav-link" to={`/account/project/${projectId}/dialer-config`}>
          <i className={`fas fa-fw fa-cogs mr-2`}></i>

          <span className="text-capitalize">Dialer Config</span>
        </Link>
      </li>}
    </>
  );

  const AccountRenderedMenu = () => (
    <>
      <div className="sidebar-heading  ">
        {isVodexLoggedin() ? "Vodex" : "Phone Bot"}
      </div>

      {checkRolesAccess(accountRoles, "projectPage") && (
        <li
          title="Quickly create an agent"
          className={`nav-item  ${getActiveClass("onboarding")} `}
        >
          <Link className="nav-link" to={`/onboarding?from=dashboard`}>
            <span className="mr-2">{icons.Quickstart}</span>
            <span className="text-capitalize">Quickstart</span>
          </Link>
        </li>
      )}

      <li className={`nav-item  ${getActiveClass("projects")} `}>
        <Link className="nav-link" to={`/account/projects`}>
          <span className="mr-2">{icons.Projects}</span>
          <span className="text-capitalize">Projects</span>
        </Link>
      </li>
      <li className={`nav-item  ${getActiveClass("call-history")} `}>
        <Link className="nav-link" to={`/account/call-history`}>
          <span className="mr-2">{icons.CallHistory}</span>
          <span className="text-capitalize">Call History</span>
        </Link>
      </li>
      <li className={`nav-item  ${getActiveClass("profile")} `}>
        <Link className="nav-link" to={`/account/profile`}>
          <span className="mr-2">{icons.Profile}</span>
          <span className="text-capitalize">Profile</span>
        </Link>
      </li>
      {selectedAccount?.params?.liveCampaigns && (
        <li className={`nav-item  ${getActiveClass("running-campaign")} `}>
          <Link className="nav-link" to={`${"/account/running-campaign"}`}>
          <i className={`fas fa-fw fa-user mr-2`}></i>{" "}
          <span className="text-capitalize">Campaigns Running</span>
          </Link>
        </li>
      )}
      {selectedAccount?.params?.cpsAccess && (
        <li className={`nav-item  ${getActiveClass("cps-settings")} `}>
          <Link className="nav-link" to={`${"/account/cps-settings"}`}>
            <i className={`fas fa-toolbox mr-2`}></i>{" "}
            <span className="text-capitalize">CPS Settings</span>
          </Link>
        </li>
      )}
      {selectedAccount?.params?.capacityAccess &&
        <li className={`nav-item  ${getActiveClass("capacity-settings")} `}>
          <Link className="nav-link" to={`${"/account/capacity-settings"}`}>
            <i className={`fas fa-toolbox mr-2`}></i>{" "}
            <span className="text-capitalize">Capacity Settings</span>
          </Link>
        </li>
      }
      <li className={`nav-item  ${getActiveClass("payments")} `}>
        <Link className="nav-link" to={`/account/payments`}>
          <span className="mr-2">{icons.Payments}</span>
          <span className="text-capitalize">Payments</span>
        </Link>
      </li>

      <hr className="sidebar-divider" />
      {access === "dashboard" && !projectId && (
        <>
          <div className="sidebar-heading  ">Account</div>

          <li
            className={`nav-item  ${getActiveClass("access")}  ${getActiveClass(
              "invite"
            )} `}
          >
            <Link className="nav-link" to={`${"access"}`}>
              <span className="mr-2">{icons.AccessManager}</span>
              <span className="text-capitalize">Access Manager</span>
            </Link>
          </li>
          <li className={`nav-item  ${getActiveClass("/history")} `}>
            <Link className="nav-link" to={`${"history"}`}>
              <span className="mr-2">{icons.AccountHistory}</span>
              <span className="text-capitalize">Account History</span>
            </Link>
          </li>
          <li className={`nav-item  ${getActiveClass("voice-cloning")} `}>
            <Link className="nav-link" to={`${"voice-cloning"}`}>
              <span className="mr-2">{icons.VoiceCloning}</span>
              <span className="text-capitalize">Voice Cloning</span>
            </Link>
          </li>
          <li className={`nav-item  ${getActiveClass("integrations")} `}>
            <Link className="nav-link" to={`${"integrations"}`}>
              <span className="mr-2">{icons.Integration}</span>
              <span className="text-capitalize">Integrations</span>
            </Link>
          </li>
        </>
      )}
    </>
  );
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#6c757d",
      borderRadius: "10px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <Fragment>
      <div className="toggle-area">
        <button
          className="btn btn-primary toggle-button"
          onClick={() => changeLeftMenuVisibility()}
        >
          <i className="fas fa-bolt"></i>
        </button>
      </div>

      <ul
        className={`navbar-nav  sidebar sidebar-dark accordion ${getCollapseClass()}`}
        id="collapseMenu"
        style={{ backgroundImage: "linear-gradient(310deg, #3a416f, #141727)" }}
      >
        {isVodexLoggedin() && (
          <Link
            className="display-mobile sidebar-brand d-flex align-items-center justify-content-center"
            style={{ marginLeft: "-60px", pointerEvents: "none" }}
            to={
              access === "dashboard"
                ? `/account/dashboard`
                : `/account/projects`
            }
          >
            <>
              <div className="sidebar-brand-icon icon-green rotate-n-15">
                <img
                  alt=""
                  style={{
                    transform: "rotate(194deg)",
                    // marginLeft: leftMenuVisibility ? "25px" : "55px",
                  }}
                  height={"67px"}
                  src={img}
                />
              </div>
              <div className="sidebar-brand-text ml-2 patron">
                Vodex <sup></sup>
              </div>
            </>
          </Link>
        )}
        {!isEmpty(whitelabelDetails) && (
          <a
            className="display-mobile sidebar-brand d-flex align-items-center justify-content-center"
            style={{
              pointerEvents: "none",
              marginLeft:
                whitelabelDetails?.marginLeft === "0"
                  ? "-60px"
                  : whitelabelDetails?.marginLeft,
            }}
            href={"void"}
          >
            {" "}
            <div className="sidebar-brand-icon icon-green rotate-n-15">
              {whitelabelDetails?.imgLink && (
                <img
                  style={{
                    transform: `rotate(${whitelabelDetails?.rotate})`,
                  }}
                  height={
                    whitelabelDetails?.height === "0"
                      ? ""
                      : whitelabelDetails?.height
                  }
                  width={
                    whitelabelDetails?.width === "0"
                      ? ""
                      : whitelabelDetails?.width
                  }
                  src={whitelabelDetails?.imgLink}
                />
              )}
            </div>
          </a>
        )}

        <hr className="sidebar-divider my-0" />

        {access === "dashboard" && (
          <li
            className={`nav-item ${getActiveClass("dashboard")} `}
            style={{ zIndex: 10 }}
          >
            <Link className="nav-link" to="/account/dashboard">
            <span className="mr-2">{icons.Dashboard}</span>
              <span>{projectId ? "Account" : "Dashboard"}</span>
            </Link>
          </li>
        )}

        {projectId && access === "project" && (
          <li
            className={`nav-item ${getActiveClass("projects")} `}
            style={{ zIndex: 10 }}
          >
            <Link
              className="nav-link"
              to="/account/projects"
              onClick={async () => await dispatch(changeMenu("account"))}
            >
              <span className="mr-2">{icons.Projects}</span>
              <span>Projects</span>
            </Link>
          </li>
        )}
        <hr className="sidebar-divider" />
        <Scrollbars
          style={{ height: "420px", color: "white" }}
          renderThumbVertical={renderThumb}
        >
          {projectId && <ProjectRenderedMenu />}
          {!projectId && <AccountRenderedMenu />}

          {(role === "super_admin" || role === "admin") && !projectId && (
            <>
              <hr className="sidebar-divider" />

              <div className="sidebar-heading  ">Admin</div>

              <li className={`nav-item  ${getActiveClass("admin")} `}>
                <Link className="nav-link" to={`${"/account/admin"}`}>
                  <i className={`fas fa-fw fa-user-cog mr-2`}></i>

                  <span className="text-capitalize">Admin</span>
                </Link>
              </li>
            </>
          )}
          {(role === "sales" || role === "super_admin") && !projectId && (
            <li className={`nav-item  ${getActiveClass("account-topup")} `}>
              <Link className="nav-link" to={`${"/account/account-topup"}`}>
                <i className={`fas fa-fw fa-wallet mr-2`}></i>

                <span className="text-capitalize">Topup Account (sales)</span>
              </Link>
            </li>
          )}

          {projectId && !isVodexLoggedin() && (
            <li className={`nav-item  ${getActiveClass("access")} `}>
              <Link
                className="nav-link"
                to={`/account/project/${projectId}/appointment-config`}
              >
                <i className={`fas fa-fw fa-calendar-plus mr-2`}></i>
                <span className="text-capitalize">Appointment</span>
              </Link>
            </li>
          )}

          {window.location.origin === "https://app.gencallsystem.com" && (
            <>
              <li className={`nav-item  ${getActiveClass("admin")} `}>
                <a
                  className="nav-link"
                  href={`https://docs.api.gencallsystem.com`}
                  target="_blank"
                >
                  <span className="mr-2">{icons.Integration}</span>
                  <span className="text-capitalize">Integration</span>
                </a>
              </li>
              <li className={`nav-item  ${getActiveClass("help-desk")} `}>
                <Link className="nav-link" to={`/account/help-desk`}>
                  <i className={`fas fa-fw fa-calendar-plus mr-2`}></i>
                  <span className="text-capitalize">Request Widget Form</span>
                </Link>
              </li>
            </>
          )}
          {(window.location.origin === "https://app.voizer.ai" ||
            (window.location.origin === "https://ghl.agencys.ai" &&
              role === "admin")) && (
              <li className={`nav-item  ${getActiveClass("help-desk")} `}>
                <Link className="nav-link" to={`/account/help-desk`}>
                  <i className={`fas fa-fw fa-calendar-plus mr-2`}></i>
                  <span className="text-capitalize">Help Library</span>
                </Link>
              </li>
            )}

          {window?.location?.origin === "https://aivoicelogin.com" && (
            <li className={`nav-item  ${getActiveClass("training")} `}>
              <Link className="nav-link" to={`/account/training`}>
                <i className={`fas fa-fw fa-calendar-plus mr-2`}></i>
                <span className="text-capitalize">Training</span>
              </Link>
            </li>
          )}
        </Scrollbars>
        {isVodexLoggedin() && (
          <div style={{ bottom: 0, position: "fixed" }}>
            <hr
              className="sidebar-divider my-0"
            // style={{ borderTop: "1px solid #ff5e00" }}
            />

            <li className={`nav-item  ${getActiveClass("support-email")} `}>
              <Link className="nav-link" to={`/account/support-email`}>
                <span className="mr-2">{icons.Support}</span>
                <span className="text-capitalize">Support</span>
              </Link>
            </li>
          </div>
        )}
      </ul>
    </Fragment>
  );
};

export default LeftMenuProject;
